import { apiTeacherReplay } from '@/api/api.js'
import AudioTool from '@/components/AudioTool/AudioTool.vue'
import AudioPlayer from '@/components/AudioPlayer/AudioPlayer.vue'
import GreQuestion from '@/components/GreQuestion/GreQuestion2.vue'
import Audio from '@/components/Audio/Audio.vue'

export default {
  components: {
    AudioPlayer,
    AudioTool,
    GreQuestion,
    Audio
  },
  data() {
    return {
      loading: true,
      askId: sessionStorage.getItem('tcl_Ask_id'),
      resultData: null,
      teacherName: '',
      greChooseId: '',
      type: '',
      secondTabName: '',
      recorded: false,
      isSupportAutoPlay: true
    }
  },
  mounted() {
    // console.log(this.askId)
    this.init()
  },
  methods: {
    init() {
      this.getTeacherReplay()
    },
    getTeacherReplay() {
      let paramsBase = {
        ask_id: this.askId
      }
      apiTeacherReplay(paramsBase).then((res) => {
        this.resultData = res.data
        var reg = /([a-zA-Z]+)/g
        this.type = reg.exec(this.resultData.class)[0]
        console.log(this.type)
        // if ('RA'.indexOf(this.type) >= 0) {
        //     this.secondTabName = 'expertDemonstration'
        // } else {
        //     this.secondTabName = 'transcriptAnswerKeys'
        // }
        this.loading = false
      })
    },
    record() {
      this.recorded = true
    },

    unSupportAutoPlay() {
      this.isSupportAutoPlay = false
    }
  }
}
