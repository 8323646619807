export default {
    props: ['audioInfo'],
    data() {
        return {
            playing: false,
            time: NaN,
            url: '',
            reset: true,
            key: 1,
        }
    },
    watch: {
        audioInfo(val, oldVal) {
            this.url = val
        }
    },
    mounted() {
        this.time = 0
        this.url = this.audioInfo
        // this.audioTime()
    },
    methods: {
        // 获取音频时间
        // audioTime() {
        //     let audioPlay = document.getElementsByTagName('audio')
        //     console.log(audioPlay[0].duration)
        //     let count = setInterval(() => {
        //         if (this.time > 0) {
        //             clearInterval(count)
        //             return
        //         }
        //         if (audioPlay[0].duration) {
        //             this.time = parseInt(audioPlay[0].duration)
        //         }

        //     }, 1000);
        // },
        // 播放音频
        playAudio() {
            let audioPlay = document.getElementsByTagName('audio')
            if (this.playing) {
                this.$refs.audioPlay.load()
                this.playing = false
            } else {
                // 如果设置了排他性，当前音频播放是，其他音频都要暂停
                [...audioPlay].forEach((item) => {
                    item.load()
                });
                this.$refs.audioPlay.play()
                // this.countDown()
                this.playing = true
            }
        },
        // 倒计时
        // countDown() {
        //     let countNum = this.time
        //     let countDown = setInterval(() => {
        //         if (this.time == 0) {
        //             clearInterval(countDown)
        //             this.playing = false
        //             this.time = countNum
        //             return
        //         }
        //         if (this.playing) {
        //             this.time = this.time - 1
        //         } else {
        //             this.time = countNum
        //             clearInterval(countDown)
        //         }

        //     }, 1000);
        // },
        onTimeupdate(res) {
            this.time = Math.floor(res.target.currentTime)
        },
        onLoadedmetadata(res) {
            this.time = Math.floor(res.target.duration)
        },
    },
}