<template>
  <div class="gre-detail" v-loading="loading">
    <!-- 机经标题 -->
    <div class="title">
      <div class="title-label">{{ $t('gre.answerTitle') }}</div>
      <div class="title-body">
        <span v-if="greTitleView">#{{ greId }} {{ greTitle }}</span>
        <span v-else></span>
      </div>
    </div>

    <div class="content">
      <div class="content-label">{{ $t('gre.answerContent') }}</div>
      <div class="content-body">
        <!-- 机经音频 -->
        <div
          v-if="greAudioView && greAudio != '' && greAudio != undefined"
          class="gre-audio"
        >
          <Audio :url="greAudio.url" @palyAudio="getAudio" />
          <!--          <AudioTool-->
          <!--            ref="audioToolRef"-->
          <!--            :audioUrl="greAudio"-->
          <!--            @funs="getAudio"-->
          <!--          ></AudioTool>-->
        </div>

        <!-- 机经录音题型 -->
        <div class="gre-recorder" v-if="greRecorderView">
          <!-- DI或ASQ的图 -->
          <div v-if="greImgSrc != null">
            <img :src="greImgSrc" alt />
          </div>
          <!-- RS -->
          <div class="switch-box" v-if="title == 'RS'">
            <el-switch
              v-model="recorderTextView"
              active-color="#DF394D"
              inactive-color="#ccc"
            ></el-switch>
            <span>建议意群</span>
          </div>
          <!-- 录音标注内容 -->
          <div v-show="!recorderTextView && title !== 'RS'">
            <span
              v-for="(item, index) in greRecorder"
              :style="item.lang == 'default' ? 'margin:0' : ''"
              :key="index"
            >
              <span v-if="!item.interval && item.stress.length == 0">{{
                item.text
              }}</span>
              <span
                v-if="
                  item.interval && item.stress.length == 0 && btnPunctuation
                "
                class="red"
                >{{ item.text }}</span
              >
              <i
                :class="item.stress[0] == indexs && btnLoud ? 'mark' : ''"
                v-show="item.stress.length > 0"
                v-for="(items, indexs) in item.text.split('')"
                :key="indexs"
                >{{ items }}</i
              >
            </span>
          </div>
          <!-- 建议意群 -->
          <div v-if="recorderTextView" class="advice">
            <div
              :class="item.color == 'white' ? 'bar hide' : 'bar'"
              :style="'flex:' + parseInt(item.flex)"
              v-for="(item, index) in greCommonData"
              :key="index"
            ></div>
          </div>
          <div class="switch-box" v-if="title == 'RA'">
            <el-switch
              v-model="btnLoud"
              active-color="#DF394D"
              inactive-color="#ccc"
            ></el-switch>
            <span>{{ $t('gre.stress') }}</span>
            <el-switch
              v-model="btnPunctuation"
              active-color="#DF394D"
              inactive-color="#ccc"
            ></el-switch>
            <span>{{ $t('gre.pausing') }}</span>
          </div>
        </div>

        <!-- 机经正文 -->
        <div v-if="greTextView" class="gre-text">
          <!-- HIW -->
          <div v-if="title == 'HIW'">
            <div class="gre-tap-word">
              <template v-for="(item, index) in greText">
                <span
                  :key="index"
                  :class="item.active ? 'active' : ''"
                  v-if="
                    item.text != '\r\n' &&
                      item.text != '\n' &&
                      item.text != '\r'
                  "
                  :style="item.lang == 'default' ? 'margin:0' : ''"
                  @click="tapWord(item)"
                >
                  <div v-if="item.text == '\\r\\n'"></div>
                  <template v-else>{{
                    item.text
                      .replace(/\’|\‘/g, "'")
                      .replace(/\？/g, '?')
                      .replace(/\。/g, '.')
                  }}</template>
                </span>
                <div :key="index" v-else></div>
              </template>
              <!-- <span
                :class="item.active?'active':''"
                @click="tapWord(item)"
                v-for="(item, index) in greText"
                :key="index"
              >{{item.text=='\\r\\n'?'':item.text}}</span>-->
            </div>
          </div>
          <!-- SWT -->
          <div v-if="title == 'SWT'">
            <div
              v-show="btnShowTranslate"
              v-text="greText.interpret.text"
            ></div>
            <span v-if="!btnShowTranslate">
              <template v-for="(item, index) in greText.content.article">
                <span
                  :key="index"
                  :class="btnShowGist ? item.color : ''"
                  v-if="
                    item.text != '\r\n' &&
                      item.text != '\n' &&
                      item.text != '\r'
                  "
                  :style="item.lang == 'default' ? 'margin:0' : ''"
                  >{{
                    item.text
                      .replace(/\’|\‘/g, "'")
                      .replace(/\？/g, '?')
                      .replace(/\。/g, '.')
                  }}</span
                >
                <div :key="index" v-else></div>
              </template>
              <!-- <span
                :class="btnShowGist?item.color:''"
                v-for="(item, index) in greText.content.article"
                :key="index"
              >{{item.text=='\\r\\n'?'':item.text.replace(/\’|\‘/g,"'")}}&nbsp;</span>-->
            </span>
            <div class="switch-box">
              <el-switch
                v-model="btnShowGist"
                @change="btnShowTranslate = false"
                active-color="#DF394D"
                inactive-color="#ccc"
              ></el-switch>
              <span>{{ $t('gre.keypoints2') }}</span>
              <el-switch
                v-model="btnShowTranslate"
                @change="btnShowGist = false"
                active-color="#DF394D"
                inactive-color="#ccc"
              ></el-switch>
              <span>{{ $t('gre.translate') }}</span>
            </div>
          </div>
          <!-- WE -->
          <div v-if="title == 'WE'">
            <!-- <span
              v-for="(item, index) in greText.content.article"
              :key="index"
            >{{item.text=='\\r\\n'?'':item.text.replace(/\’|\‘/g,"'")}}&nbsp;</span>-->
            <template v-for="(item, index) in greText.content.article">
              <span
                :key="index"
                :class="btnShowGist ? item.color : ''"
                v-if="
                  item.text != '\r\n' && item.text != '\n' && item.text != '\r'
                "
                :style="item.lang == 'default' ? 'margin:0' : ''"
                >{{
                  item.text
                    .replace(/\’|\‘/g, "'")
                    .replace(/\？/g, '?')
                    .replace(/\。/g, '.')
                }}</span
              >
              <div :key="index" v-else></div>
            </template>
          </div>
        </div>

        <!-- 机经填词 -->
        <div class="gre-fill-input" v-if="greFillInputView">
          <h4>建议先用笔头记录，在录音播放完以后答题</h4>
          <span
            v-for="(item, index) in greFill"
            :key="index"
            :style="item.lang == 'default' ? 'margin:0' : ''"
          >
            <template v-if="!item.isEmpty">
              <span
                :style="item.lang == 'default' ? 'margin:0' : ''"
                v-if="
                  item.text != '\r\n' && item.text != '\n' && item.text != '\r'
                "
                >{{ item.text }}</span
              >
              <div v-else></div>
            </template>
            <!-- <span v-if="item.text.indexOf('_') < 0">&nbsp;{{item.text=='\\r\\n'?'':item.text}}</span> -->
            <el-input
              v-model="greFillSelected[item.count]"
              v-if="item.isEmpty"
              placeholder="输入单词"
            ></el-input>
          </span>
        </div>
        <!-- 机经选词填空FIB -->
        <div class="gre-fill" v-if="greFillView">
          <!-- RFIB -->
          <span v-if="title == 'RFIB'">
            <span
              v-for="(item, index) in greFill"
              :key="index"
              :style="item.lang == 'default' ? 'margin:0' : ''"
            >
              <template v-if="item.text.indexOf('_') < 0">
                <span
                  :style="item.lang == 'default' ? 'margin:0' : ''"
                  v-if="
                    item.text != '\r\n' &&
                      item.text != '\n' &&
                      item.text != '\r'
                  "
                  >{{ item.text }}</span
                >
                <div v-else></div>
              </template>
              <!-- <span v-if="item.text.indexOf('_')<0">&nbsp;{{item.text=='\\r\\n'?'':item.text}}</span> -->
              <el-select
                v-model="greFillSelected[item.count]"
                v-if="item.text.indexOf('_') >= 0"
                :placeholder="$t('placeholder.pleaseSelect')"
              >
                <el-option
                  v-for="(items, indexs) in greFillOption"
                  :key="indexs"
                  :label="items"
                  :value="items"
                ></el-option>
              </el-select>
            </span>
          </span>
          <!-- FIBW -->
          <span v-if="title == 'FIBW'">
            <span v-for="(item, index) in greFill" :key="index">
              <template v-if="item.text.indexOf('_') < 0">
                <span
                  v-if="
                    item.text != '\r\n' &&
                      item.text != '\n' &&
                      item.text != '\r'
                  "
                  >{{
                    item.text
                      .replace(/\’|\‘/g, "'")
                      .replace(/\？/g, '?')
                      .replace(/\。/g, '.')
                  }}</span
                >
                <div v-else></div>
              </template>
              <!-- <span v-if="item.choose.length==0">&nbsp;{{item.text=='\\r\\n'?'':item.text}}</span> -->
              <el-select
                v-model="greFillSelected[item.count]"
                v-if="item.choose.length != 0"
                :placeholder="$t('placeholder.pleaseSelect')"
              >
                <el-option
                  v-for="(items, indexs) in item.choose"
                  :key="indexs"
                  :label="items"
                  :value="items"
                ></el-option>
              </el-select>
            </span>
          </span>
        </div>
        <!-- 机经选择(多选) -->
        <div class="gre-select" v-if="greSelectView">
          <h2>{{ greSelectTitle }}</h2>
          <el-checkbox
            v-model="greSelectedList"
            v-for="(item, index) in greSelectList"
            :key="index"
            :label="index"
            border
            size="medium"
            >{{ item.text }}</el-checkbox
          >
        </div>
        <!-- 机经选择(多选) -->
        <div class="gre-select" v-if="greRadioSelectView">
          <h2>{{ greRadioSelectTitle }}</h2>
          <div class="stem-content">{{ greMcsSelectTitle }}</div>
          <el-radio-group v-model="greRadioSelectedList" size="medium" border>
            <el-radio-button
              v-for="(item, index) in greRadioSelectList"
              :key="index"
              :label="item.text"
            ></el-radio-button>
          </el-radio-group>
        </div>
        <!-- 我的练习 -->
        <div class="gre-my-practice" v-if="greMyPracticeView">
          <h2>
            {{ $t('gre.myPractice') }}
            <!-- 一直显示调试manual="true" hide-after="0"  -->
            <el-tooltip class="item" placement="right-start" v-if="tipsViews">
              <i class="el-icon-warning-outline"></i>
              <div slot="content" style="max-width:200px;line-height:1.5">
                <b>Form：</b>
                <br />
                <span> <b>2</b> Contains 50-70 words </span>
                <br />
                <span> <b>1</b> Contains 40-49 words or 71-100 words </span>
                <br />
                <span>
                  <b>0</b> Contains less than 40 words or more than 100 words.
                  Summary is written in capital letters, contains no punctuation
                  or consists only of bullet points or very short sentences
                </span>
              </div>
            </el-tooltip>
          </h2>
          <el-input
            style="margin-top:8px;"
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 8 }"
            :placeholder="$t('placeholder.clickHereToAnswer')"
            v-model="grePracticeText"
            @input="descInput"
          ></el-input>
          <!-- <div
            style="font-size:14px;color:#999;margin-top:6px;"
          >{{$t('gre.wordcount')}}：{{grePracticeText.split(".").length+grePracticeText.split(",").length+grePracticeText.split(" ").length-3}}</div>
        </div> -->
          <div style="font-size:14px;color:#999;margin-top:6px;">
            {{ $t('gre.wordcount') }}：{{ grePracticeTextNum }}
          </div>
        </div>

        <!-- 拖拽选项 -->
        <div class="gre-drag" v-if="greDragView">
          <h2 style="font-weight:normal;color:#999;">
            {{ $t('gre.dragTip') }}
            <!-- 一直显示调试manual="true" hide-after="0"  -->
            <el-tooltip class="item" placement="right-start">
              <i class="el-icon-warning-outline"></i>
              <div slot="content">{{ greTip }}</div>
            </el-tooltip>
          </h2>
          <ul>
            <draggable
              v-model="greDragList"
              @start="drag = true"
              @end="drag = false"
            >
              <li v-for="(item, index) in greDragList" :key="index">
                {{ index + 1 }}）{{ item.text }}
              </li>
            </draggable>
          </ul>
        </div>

        <!-- 提交按钮 -->
        <div class="gre-submit" v-if="greSubmitView">
          <el-button
            class="submit"
            type="danger"
            round
            @click="submitQuestion"
            >{{ $t('gre.submit') }}</el-button
          >
        </div>

        <!-- 录音内容 -->
        <div class="gre-recorder-submit" v-if="greRecorderViews">
          <h2>{{ $t('gre.myPractice') }}</h2>
          <div class="gre-recorder-submit__inner" style="min-height:41px">
            <!-- v-loading="recorderUrl==null" -->
            <!--            <Audio :url="recorderUrl" />-->
            <AudioPlayer
              v-if="recorderUrl != null && recorderUrl != ''"
              :audioInfo="recorderUrl"
              v-loading="loading"
            ></AudioPlayer>
            <div class="recognition" v-if="recorderUrl != null">
              <h1>
                {{ $t('gre.recognitionRate') }}
                <b>AI</b>
              </h1>
              <template v-if="recorderArticle && recorderArticle.length">
                <span
                  v-for="(item, index) in recorderArticle"
                  :class="item.isWrong ? 'wrong' : ''"
                  :key="index"
                  >{{ item.text }}</span
                >
              </template>
              <template v-else>
                <span>识别中...</span>
              </template>
            </div>
          </div>

          <div
            class="recorder-frame"
            @click="recorderUrl = recorderArticle = null"
          >
            <Recorder @func="getMsgFormSon" @record="record"></Recorder>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiGetQuestion, apiQuestionSave } from '@/api/api.js'
import draggable from 'vuedraggable'
import canAutoPlay from 'can-autoplay'
import AudioTool from '@/components/AudioTool/AudioTool.vue'
import Audio from '@/components/Audio/Audio.vue'
import Recorder from '@/components/Recorder/Recorder.vue'
import AudioPlayer from '@/components/AudioPlayer/AudioPlayer.vue'
import * as upload from '@/utils/upload'

export default {
  components: {
    draggable,
    AudioTool,
    Audio,
    Recorder,
    AudioPlayer
  },
  props: {
    title: String,
    questionId: null,
    status: null,
    claId: null
  },
  data() {
    return {
      // 模块显示
      greTitleView: false,
      greTextView: false,
      greAudioView: false,
      greSelectView: false,
      greRadioSelectView: false,
      greMyPracticeView: false,
      greSubmitView: false,
      greDragView: false,
      greFillView: false,
      greFillInputView: false,
      greRecorderView: false,
      greRecorderViews: false,
      tipsViews: true,
      recorderTextView: false, //建议意群开关控制
      // 机经详情
      greTitle: '',
      greId: '',
      greSelectList: [], //选择题数据
      greSelectTitle: '', //选择题问题
      greSelectedList: [],
      greSelected: '', //已选择的数据
      greRadioTitle: '',
      greRadioSelectList: [], //选择题数据
      greRadioSelectTitle: '', //选择题问题
      greMcsSelectTitle: '',
      greRadioSelectedList: [],
      greRadioSelected: '', //已选择的数据
      greText: [], //题目内容
      greTip: '', //提示信息
      greDragList: [], //拖拽数据
      grePracticeText: '', //我的练习内容
      grePracticeTextNum: 0, //联系字数统计
      greAudio: '', //音频数据
      greRecorder: '', //录音数据
      greCommonData: [], //公用数组
      greImgSrc: null, //图片链接
      recorderUrl: '', //录音链接
      recorderArticle: null, //录音识别数据

      // 填空题
      greFill: [],
      greFillOption: [],
      greFillSelected: [],

      // 切换按钮
      btnShowGist: false, //显示主旨
      btnShowTranslate: false, //显示翻译
      btnLoud: false, //建议重读
      btnPunctuation: false, //建议断句
      btnAdvise: false, //建议意群

      // 加载
      loading: false,
      // 传给父组件的值
      toDadyData: {
        count: null,
        collected: false,
        data: null
      }
    }
  },
  mounted() {
    this.getQuestion()
  },
  methods: {
    getQuestion() {
      if (this.questionId == null) {
        return
      }
      this.loading = true
      let paramsBase = {
        id: this.questionId
      }
      apiGetQuestion(paramsBase).then((res) => {
        if (res.code != 200) {
          this.loading = false
        }
        console.log(res.data)
        this.greSelectedList = []
        this.loading = false
        // 传给父组件的值赋值
        this.toDadyData.count = res.data.top.time
        this.toDadyData.collected = res.data.top.collected
        this.toDadyData.data = res.data
        this.toDady()
        // 添加关键字段到session
        let questionDetail = {
          id: res.data.id,
          title: res.data.title,
          collected: res.data.top.collected,
          time: res.data.top.time,
          previousID: res.data.previousID,
          nextId: res.data.nextId,
          all: res.data.top.all,
          crt: res.data.top.crt,
          subtitles: res.data.subtitles,
          content: res.data.content
        }
        // console.log(res.data.top.collected)
        // console.log('传给session的题目的值', questionDetail)
        sessionStorage.setItem('questionDetail', JSON.stringify(questionDetail))

        let optionCount = 1 //选项计数
        // 公共赋值
        this.greTitle = res.data.title
        this.greId = res.data.id
        this.greTitleView = true
        this.greFill = this.greFillSelected = []

        if (
          'MCM'.indexOf(this.title) >= 0 &&
          sessionStorage.getItem('classify') == 'Read'
        ) {
          this.greTitle = res.data.check.title
          this.greSelectTitle = res.data.check.stem
          this.greSelectList = res.data.check.options
          this.greSelectView = true
          if (this.status == null) {
            this.greSubmitView = true
          }
        }
        if ('RO'.indexOf(this.title) >= 0) {
          this.greDragList = res.data.check.items
          this.greDragList.forEach((item, index) => {
            item.index = index
          })
          this.greDragView = true
          if (this.status == null) {
            this.greSubmitView = true
          }
        }
        if ('RFIB'.indexOf(this.title) >= 0 && this.title != 'FIB') {
          // 填空题构建
          this.greFillOption = res.data.content.choose
          this.greFill = res.data.content.article
          optionCount = 0 //选项计数
          for (let i = 0; i < this.greFill.length; i++) {
            if (this.greFill[i].text == '_') {
              this.greFillSelected.push('')
              this.greFill[i].count = optionCount
              optionCount = optionCount + 1
            }
          }
          this.greFillView = true
          if (this.status == null) {
            this.greSubmitView = true
          }
        }
        if ('FIBW'.indexOf(this.title) >= 0 && this.title != 'FIB') {
          this.greFill = res.data.content.article
          this.greFillView = true
          if (this.status == null) {
            this.greSubmitView = true
          }
          optionCount = 0 //选项计数
          for (let i = 0; i < this.greFill.length; i++) {
            if (this.greFill[i].text == '_') {
              this.greFillSelected.push('')
              this.greFill[i].count = optionCount
              optionCount = optionCount + 1
            }
          }
          // console.log(this.greFill)
        }
        if ('SWT,WE'.indexOf(this.title) >= 0) {
          this.greText = res.data
          this.greTextView = true
          if (this.status == null) {
            this.greSubmitView = true
            this.greMyPracticeView = true
          }
        }
        if ('SST,WFD'.indexOf(this.title) >= 0) {
          this.greAudio = res.data.audio
          this.greAudioView = true
          if (this.status == null) {
            this.greSubmitView = true
            this.greMyPracticeView = true
          }
        }
        if ('WFD'.indexOf(this.title) >= 0) {
          this.tipsViews = false
        }
        if ('MCM,HCS,SMW'.indexOf(this.title) >= 0) {
          if (sessionStorage.getItem('classify') == 'Read') {
            this.greSelectTitle = res.data.check.stem
          } else {
            this.greSelectTitle = res.data.check.title
          }
          this.greSelectList = res.data.check.options
          this.greAudio = res.data.audio
          this.greAudioView = this.greSelectView = true
          if (this.status == null) {
            this.greSubmitView = true
          }

          window.console.log('greAudio:', this.greAudio, this.greAudioView)
        }
        if ('MCS'.indexOf(this.title) >= 0) {
          this.greAudio = res.data.audio
          this.greAudioView = this.greSelectView = true
          this.greRadioTitle = res.data.check.title
          this.greRadioSelectTitle = res.data.check.title
          this.greMcsSelectTitle = res.data.check.stem
          this.greRadioSelectList = res.data.check.options
          this.greRadioSelectView = true
          if (this.status == null) {
            this.greSubmitView = true
          }
        }
        if ('FIB'.indexOf(this.title) >= 0) {
          this.greAudio = res.data.audio
          this.greFill = res.data.content.article
          optionCount = 0 //input计数
          for (let i = 0; i < this.greFill.length; i++) {
            if (this.greFill[i].text == '_') {
              this.greFillSelected.push('')
              this.greFill[i].count = optionCount
              optionCount = optionCount + 1
            }
          }
          this.greAudioView = this.greFillInputView = true
          if (this.status == null) {
            this.greSubmitView = true
          }
        }
        if ('HIW'.indexOf(this.title) >= 0) {
          this.greAudio = res.data.audio
          this.greText = res.data.content.article.map((item, index) => {
            return {
              ...item,
              index
            }
          })
          for (let i = 0; i < this.greText.length; i++) {
            this.greText[i].active = false
          }
          console.log(this.greText)
          this.greAudioView = this.greTextView = true
          if (this.status == null) {
            this.greSubmitView = true
          }
        }
        if ('RA'.indexOf(this.title) >= 0) {
          this.greRecorder = res.data.content.article
          this.greRecorderView = true
          if (this.status == null) {
            this.greRecorderViews = true
          }
        }
        if ('RS'.indexOf(this.title) >= 0) {
          this.greRecorder = res.data.subtitles.article
          this.greAudio = res.data.audio
          this.greCommonData = res.data.sense_group.content
          this.greAudioView = true
          this.greRecorderView = true
          if (this.status == null) {
            this.greRecorderViews = true
          }
          this.btnLoud = this.btnPunctuation = true
        }
        if ('DI'.indexOf(this.title) >= 0) {
          this.greImgSrc = res.data.img.url
          this.greRecorderView = true
          if (this.status == null) {
            this.greRecorderViews = true
          }
        }
        if ('RL'.indexOf(this.title) >= 0) {
          this.greRecorder = res.data.subtitles.article
          this.greAudio = res.data.audio
          this.greAudioView = true
          this.greRecorderView = true
          if (this.status == null) {
            this.greRecorderViews = true
          }
        }
        if ('ASQ'.indexOf(this.title) >= 0) {
          if (res.data.audio.url.length > 0) {
            this.greAudio = res.data.audio
            this.greAudioView = true
          } else {
            this.greAudioView = false
          }
          if (res.data.img != null) {
            this.greImgSrc = res.data.img.url
          } else {
            this.greImgSrc = null
          }
          this.greRecorderView = true
          if (this.status == null) {
            this.greRecorderViews = true
          }
        }

        if (this.greRecorderView) {
          this.recorderUrl = null
          this.recorderArticle = null
        }
        this.$nextTick(() => {
          this.isSupportAutoPlay().then(({ result }) => {
            if (result === true) {
              // Can auto-play
            } else {
              this.$emit('record')
              this.$emit('unSupportAutoPlay')
            }
          })
        })
      })
    },
    // 点击单词
    tapWord(e) {
      if (e.active) {
        e.active = false
        this.greFillSelected.forEach((item, index) => {
          if (item == e.index) {
            this.greFillSelected.splice(index, 1)
          }
        })
      } else {
        e.active = true
        this.greFillSelected.push(e.index)
      }
      this.$forceUpdate()
    },
    // 给父组件传值
    toDady() {
      this.$emit('getMySon', this.toDadyData)
    },
    // 给父组件传递音频状态
    getAudio(data) {
      this.$emit('audioPlay', data)
    },
    // 提交问题
    submitQuestion() {
      if (!this.greSubmitView) return
      let contents = []
      var formData = new window.FormData()
      formData.append('qid', this.questionId)
      formData.append('claid', this.claId)
      /*
              if (this.greSelected.length != '') {
                  // 暂无数据
                  formData.append("contents[0]", this.greSelected);
              }*/

      if (this.greSelectedList.length > 0) {
        // 暂无数据
        this.greSelectedList.forEach(function(item, index) {
          formData.append('contents[' + index + ']', item)
        })
      }

      if (this.greRadioSelectedList.length > 0) {
        formData.append(
          'contents[0]',
          this.greRadioSelectList
            .map((item) => item.text)
            .indexOf(this.greRadioSelectedList)
        )
      }

      if (this.grePracticeText.length != '') {
        formData.append('contents[0]', this.grePracticeText)
      }
      if (this.greDragList.length > 0) {
        let idxArr = []
        this.greDragList.forEach((item) => {
          idxArr.push(item.index)
        })
        idxArr.forEach((element, index) => {
          formData.append('contents[' + [index] + ']', element)
        })
      }
      if (this.greFillSelected.length > 0) {
        this.greFillSelected.forEach((element, index) => {
          formData.append('contents[' + [index] + ']', element)
        })
      }
      let paramsBase = formData
      return apiQuestionSave(paramsBase).then((res) => {
        sessionStorage.setItem('claid', null)
        this.$layer.msg('提交成功')
        console.log(res)
        sessionStorage.setItem('answerDetail', JSON.stringify(res.data))
        this.$parent.childSubmit()
      })
    },
    getMsgFormSon(data) {
      var formData = new window.FormData()
      var name = this.getMath() + this.getMath() + this.getMath() + '.wav'
      formData.append('claid', this.claId) //注意替换成作业id,从班级作业过来的有此值
      formData.append('qid', this.questionId)

      const loading = this.$loading({
        lock: true,
        text: this.$t('m.loadingRecord'),
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0)',
        target: document.querySelector('.gre-recorder-submit__inner')
      })
      upload.file({
        file: data,
        fileName: name,
        onSuccess: ({ data }) => {
          formData.append('oss_url', data.oss_url)
          let paramsBase = formData
          this.recorderUrl = data.oss_url

          loading.close()

          apiQuestionSave(paramsBase).then((res) => {
            sessionStorage.setItem('claid', null)
            if (
              res.data.content[1] &&
              res.data.content[1].article.length != 0
            ) {
              this.recorderArticle = res.data.content[1].article
            }
            this.$parent.saveData = res.data
          })
        }
      })
    },
    //字数统计
    descInput() {
      var reg = /(\w)+/gi
      var txtVal = this.grePracticeText.match(reg).length
      this.grePracticeTextNum = txtVal
    },
    //生成随机数
    getMath() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
    },

    clearValue() {
      this.grePracticeText = ''
    },

    record() {
      this.$emit('record')
    },

    isSupportAutoPlay() {
      return canAutoPlay.audio()
    }
  },
  watch: {
    questionId() {
      this.greAudioView = false
      this.getQuestion()
    }
  }
}
</script>

<style lang="scss" scoped>
.gre-fill,
.gre-fill-input {
  .el-input {
    width: auto;
  }
  .el-input__inner {
    width: 100px;
    color: #4366ca;
    height: 35px;
    line-height: 35px;
  }
  .el-select {
    margin: 0 6px;
  }
}
.gre-select {
  .el-radio__label {
    white-space: normal;
    line-height: 1.5;
    word-break: break-word;
  }

  .el-radio-group {
    width: 100%;
  }
  .el-checkbox__label {
    white-space: normal;
    word-break: break-word;
    line-height: 1.5;
  }
  .el-radio--medium.is-bordered {
    padding: 8px 10px !important;
    height: auto;
  }
  .el-checkbox--medium.is-bordered {
    padding: 8px 10px !important;
    height: auto;
  }
  ::v-deep .el-radio-button__inner {
    border-left: 1px solid #dcdfe6 !important;
  }
  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    border-color: #4366ca;
    color: unset;
    background-color: unset;
    -webkit-box-shadow: unset;
    box-shadow: unset;
  }
  .el-radio-button__inner {
    border-radius: 4px;
    text-align: left;
    width: 100%;
    &:hover {
      color: unset;
    }
    &:last-child {
      border-radius: 4px;
    }
  }
}
.gre-detail {
  border-left: 1px solid #bfbfbf;
  padding-left: 10px;
  ::v-deep .el-input {
    > input {
      height: 30px;
      line-height: 30px;
      width: 120px;
    }
  }
  .title {
    display: flex;
    font-size: 14px;
    color: #9c9c9c;
    .title-label {
      margin-right: 10px;
    }
    .title-body {
      color: #3b86ff;
      cursor: pointer;
    }
  }
  .content {
    display: flex;
    margin-top: 12px;
    .content-label {
      font-size: 14px;
      color: #9c9c9c;
      margin-right: 10px;
      min-width: 60px;
    }
    .content-body {
    }
  }

  h1,
  h2 {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .gre-text {
    font-size: 15px;
    line-height: 2;
    margin-bottom: 20px;

    span {
      display: inline-block;
      margin: 0 5px;
    }

    span.red {
      color: #df394d;
    }
  }

  .gre-audio {
    margin-bottom: 20px;
  }

  .gre-fill-input {
    margin-bottom: 20px;
    white-space: normal;
    line-height: 2.5;

    & > span {
      display: inline-block;
      margin: 0 5px;
    }

    h4 {
      font-size: 14px;
      color: #999999;
      margin-bottom: 15px;
      font-weight: normal;
    }
  }

  .gre-fill {
    margin-bottom: 20px;

    & > span > span {
      line-height: 3;
      margin: 0 5px;
      display: inline-block;
    }
  }

  .gre-my-practice,
  .gre-drag {
    margin-bottom: 20px;

    h2 {
      font-size: 14px;
      margin-bottom: 4px;

      i {
        color: #df394d;
        margin-left: 8px;
        font-size: 16px;
        vertical-align: baseline;
      }
    }
  }
}

.gre-drag {
  margin-bottom: 20px;

  ul {
    list-style: none;
    padding: 15px 0;

    li {
      font-size: 16px;
      line-height: 1.5;
      border-radius: 10px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.14);
      padding: 16px 20px;
      margin-bottom: 10px;
    }
  }
}

.gre-tap-word {
  border: 1px solid #cccccc;
  border-radius: 10px;
  padding: 14px 20px;

  span {
    cursor: pointer;
    margin-right: 10px;
    display: inline-block;
  }

  span.active {
    background-color: #ffb9be;
  }
}

.gre-recorder-submit {
  h2 {
    font-size: 14px;
  }
}

.gre-recorder {
  margin-bottom: 15px;
  white-space: normal;

  img {
    display: block;
    margin: 15px auto;
  }

  div > span {
    margin: 0 5px;
    line-height: 2.4;
    display: inline-block;

    &.no-right {
      margin-right: unset;
    }
  }

  i {
    font-style: normal;
    position: relative;
  }

  i.mark::before {
    content: '·';
    color: #df394d;
    position: absolute;
    top: -8px;
    font-size: 35px;
    line-height: 10px;
  }

  span.red {
    color: #df394d;
  }

  .advice {
    display: flex;

    .bar {
      background-color: #a2b3e5;
      border-radius: 4px;
      height: 8px;
    }

    .bar.hide {
      visibility: hidden;
    }
  }
}

.gre-submit {
  text-align: center;
  padding: 20px;

  button {
    background-color: #df394d;
    padding: 12px 40px;
  }
}

.switch-box {
  margin: 20px 0;

  span {
    vertical-align: middle;
    margin: 0 20px 0 7px;
  }
}

.recognition {
  position: relative;
  border: 2px solid #ffb9be;
  padding: 20px;
  border-radius: 10px;
  margin: 30px 0;
  white-space: normal;

  span {
    margin-right: 10px;
    display: inline-block;
  }

  span.wrong {
    background-color: #ffb9be;
  }

  h1 {
    display: block;
    position: absolute;
    background-color: white;
    padding: 0 32px 0 8px;
    font-size: 16px;
    top: -24px;
    left: 50px;

    b {
      background: linear-gradient(
        150deg,
        rgba(240, 80, 101, 1) 0%,
        rgba(253, 167, 92, 1) 100%
      );
      border-radius: 4px;
      padding: 4px;
      position: absolute;
      right: 6px;
      top: -6px;
      font-size: 12px;
      color: white;
      line-height: 1;
      font-weight: normal;
    }
  }
}

.recorder-frame {
  padding: 30px 0;
}

.gre-select {
  .el-radio-button {
    text-align: left;
    width: 100%;
    margin: 12px 0px;
  }
  .stem-content {
    line-height: 28px;
    margin-bottom: 20px;
  }
}
</style>
